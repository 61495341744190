import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import Fab from "@mui/material/Fab";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditIcon from "@mui/icons-material/Edit";
import configData from "./config.json";
import AppbarMenu from "./AppbarMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OutlinedInput from "@mui/material/OutlinedInput";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import "./css/App.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemPedido = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  backgroundColor: "#18544d",
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function backToMenuMesa() {
  sessionStorage.removeItem("currentOper");
  window.location.href = "/MenuMesa";
}

function backToMesas() {
  sessionStorage.removeItem("currentOper");
  sessionStorage.removeItem("currentMesa");
  sessionStorage.removeItem("nombreMesa");

  window.location.href = "/Mesas";
}

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

async function getUsuariosAPI(idUsuario, token) {
  const data = await fetch(configData.getUsuarios, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
  });
  return await data.json();
}

async function setUsuarioAPI(idUsuario, token, usuarioInsUpd) {
  const data = await fetch(configData.setUsuario, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idUsuario: idUsuario,
      usuario: usuarioInsUpd,
    }),
  });
  return await data.json();
}

export default function UsuariosAdmin(props) {
  const usuarioNewSch = {
    id: -1,
    tipo: 3,
    nombre: "",
    apellido: "",
    rut: "",
    username: "",
    password: "",
    foto: "",
    telefono: "",
    mac: "",
  };
  //-------------------------------------------

  const usuario = sessionStorage.getItem("nombre");
  const currentMesa = sessionStorage.getItem("currentMesa");
  const nombreMesa = sessionStorage.getItem("nombreMesa");

  //const currentOper = sessionStorage.getItem("currentOper");

  const timer = React.useRef();
  const [value, setValue] = React.useState(0);
  const [showLoadCocina, setShowLoadCocina] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [msgCocina, setMsgCocina] = React.useState(false);
  const [resultCocina, setResultCocina] = React.useState();
  const [msgResultCocina, setMsgResultCocina] = React.useState();

  const [isLoading, setIsLoading] = useState(true);

  const [openDialog, setOpenDialog] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [passError, setPassError] = React.useState(false);
  const [usernameError, setUsernameError] = React.useState(false);
  const [nombreError, setNombreError] = React.useState(false);
  const [apellidoError, setApellidoError] = React.useState(false);

  const [usuarios, setUsuarios] = React.useState([]);
  const [idxUsuarioEdit, setIdxUsuarioEdit] = React.useState(-1);
  const [usuarioNew, setUsuarioNew] = React.useState(usuarioNewSch);
  const [usuarioEdit, setUsuarioEdit] = React.useState({});

  const handleChangeTabsMenu = (event, newValue) => {
    setShowPassword(false);
    setValue(newValue);
    setUsuarioNew(usuarioNewSch);

    setPassError(false);
    setNombreError(false);
    setUsernameError(false);
    setApellidoError(false);

    window.scrollTo({ top: 0, behavior: "smooth" });

  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const setUsuarioNewValues = (prop) => (event) => {
    if (prop === "telefono") {
      if (isNaN(event.target.value)) return null;
      else setUsuarioNew({ ...usuarioNew, [prop]: event.target.value });
    } else {
      setUsuarioNew({ ...usuarioNew, [prop]: event.target.value });
    }
  };

  const setUsuarioEditValue = (prop) => (event) => {
    if (prop === "telefono") {
      if (isNaN(event.target.value)) return null;
      else setUsuarioEdit({ ...usuarioEdit, [prop]: event.target.value });
    } else {
      setUsuarioEdit({ ...usuarioEdit, [prop]: event.target.value });
    }
  };

  const insUpdUsuario = (inOper) => {
    setPassError(false);
    setNombreError(false);
    setUsernameError(false);
    setApellidoError(false);

    if (inOper === 0) {
      usuarioNew.nombre === "" ? setNombreError(true) : setNombreError(false);
      usuarioNew.apellido === ""
        ? setApellidoError(true)
        : setApellidoError(false);
      usuarioNew.username === ""
        ? setUsernameError(true)
        : setUsernameError(false);
      usuarioNew.password === "" ? setPassError(true) : setPassError(false);
    } else {
      usuarioEdit.nombre === "" ? setNombreError(true) : setNombreError(false);
      usuarioEdit.apellido === ""
        ? setApellidoError(true)
        : setApellidoError(false);
      usuarioEdit.username === ""
        ? setUsernameError(true)
        : setUsernameError(false);
    }

    if (
      ((usuarioNew.nombre === "" ||
      usuarioNew.apellido === "" ||
      usuarioNew.username === "" ||
      usuarioNew.password === "") && (inOper === 0))
      ||
      ((usuarioEdit.nombre === "" ||
      usuarioEdit.apellido === "" ||
      usuarioEdit.username === "") && (inOper === 1))
    ) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      Swal.fire({
        customClass: { container: "App.css" },

        title: "ERROR EN DATOS",
        text: "DEBES COMPLETAR TODOS LOS DATOS OBLIGATORIOS...",
        icon: "error",
      });

      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setOpenDialog(false);

      let idUsuario = sessionStorage.getItem("userid");
      let token = sessionStorage.getItem("accessToken");

      setShowLoadCocina(true);
      setLoading(true);

      setResultCocina("info");
      setMsgResultCocina(
        inOper === 0 ? "Creando Usuario..." : "Actualizando Usuario..."
      );
      setMsgCocina(true);

      //Actualiza o crea el producto
      setUsuarioAPI(
        idUsuario,
        token,
        inOper === 0 ? usuarioNew : usuarioEdit
      ).then(
        (result) => {
          if ("error" in result) logout();

          timer.current = window.setTimeout(() => {
            setMsgCocina(false);
            setLoading(false);
            setShowLoadCocina(false);

            if (result.codigo === 0 || result.codigo === 1) {
              Swal.fire({
                title: inOper === 0 ? usuarioNew.nombre : usuarioEdit.nombre,
                text: inOper === 0 ? "CREADO" : "ACTUALIZADO",
                icon: "success",
              }).then((result) => {
                window.location.href = "/UsuariosAdmin";
              });
            } else
              Swal.fire({
                title: "ERROR",
                text: result.message,
                icon: "error",
              });
          }, 2000);
        },

        (error) => {
          setMsgCocina(false);
          setLoading(false);
          setShowLoadCocina(false);

          Swal.fire({
            title: "ERROR",
            text: error,
            icon: "error",
          });
        }
      );
    }
  };

  const editaUsuario = (idx) => {
    setPassError(false);
    setNombreError(false);
    setUsernameError(false);
    setApellidoError(false);
    setShowPassword(false);

    setUsuarioEdit(usuarios[idx]);

    setIdxUsuarioEdit(idx);

    setOpenDialog(true);
  };

  useEffect(() => {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    getUsuariosAPI(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setUsuarios(result);
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);

      let currJornada = sessionStorage.getItem("idJornada");

      if (parseInt(currJornada) <= 0) {
        Swal.fire({
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: "rgba(0,0,123,0.4)",
          title: "SIN JORNADA LABORAL",
          text: "¿Quieres abrir la jornada laboral?",
          showDenyButton: true,
          confirmButtonText: "SI",
          denyButtonText: "NO",
          icon: "question",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/JornadasAdmin";
          } else {
            window.location.href = "/HomeAdmin";
          }
        });
      }
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      {AppbarMenu()}
      <div>
        <Box
          sx={{
            p: 1,
            bgcolor: "white",
            flexDirection: "row",
            backgroundColor: "white",
            display: "flex",
            borderBottom: 1,
            borderColor: "primary.main",
            maxWidth: 600,
            minWidth: 390,
            zIndex: 1000,
            top: 0,
            mt: 8.8,
          }}
          background-color="white"
          position="fixed"
          key="box-space"
          spacing={2}
          alignItems="left"
        >
          <Chip
            sx={{ mt: 1, mr: 1 }}
            size="large"
            label=" USUARIOS "
            variant="filled"
            color="info"
            width="100%"
          />
          <Tabs
            value={value}
            onChange={handleChangeTabsMenu}
            aria-label="tabs usuarios"
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab icon={<PersonIcon />} label="" />
            <Tab icon={<PersonAddIcon />} label="" />
          </Tabs>
        </Box>
      </div>

      <Box
        sx={{
          mt: 18,
          minWidth: 390,
          mb: 10,
        }}
      >
        {value === 0 && (
          <div>
            {usuarios.map((usuario, index) => (
              <Stack spacing={1} key={usuario.id + "-" + usuario.tipo}>
                <Item>
                  <Chip
                    sx={{
                      mr: 1,
                      boxShadow: 20,
                      width: "290px",

                      textAlign: "left",
                    }}
                    size="large"
                    label={usuario.nombre}
                    variant="outlined"
                    color="success"
                  />

                  <IconButton
                    sx={{ boxShadow: 10, color: "#105aa3" }}
                    size="small"
                    aria-label="edit-item"
                    color="inherit"
                    onClick={() => {
                      editaUsuario(index);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Item>
              </Stack>
            ))}
          </div>
        )}

        {value === 1 && (
          <div>
            <Stack spacing={1}>
              <Typography
                sx={{ minWidth: 300, flex: "1 1 100%", mt: 1, ml: 1, mb: 1 }}
                variant="h7"
                id="tableTitle"
                component="div"
              >
                <b>AGREGA AL USUARIO</b>
              </Typography>

              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE * </b>
                </Typography>
                <OutlinedInput
                  error={nombreError}
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="nombreCrea"
                  value={usuarioNew.nombre}
                  onChange={setUsuarioNewValues("nombre")}
                  label="nombre"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>APELLIDO *</b>
                </Typography>
                <OutlinedInput
                  error={apellidoError}
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="apellido"
                  value={usuarioNew.apellido}
                  onChange={setUsuarioNewValues("apellido")}
                  label="apellido"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>RUT </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="rut"
                  value={usuarioNew.rut}
                  onChange={setUsuarioNewValues("rut")}
                  label="rut"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>USERNAME * </b>
                </Typography>
                <OutlinedInput
                  error={usernameError}
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="username"
                  value={usuarioNew.username}
                  onChange={setUsuarioNewValues("username")}
                  label="username"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>PASSWORD *</b>
                </Typography>
                <OutlinedInput
                  error={passError}
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={usuarioNew.password}
                  onChange={setUsuarioNewValues("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TIPO USUARIO * </b>
                </Typography>
                <Select
                  sx={{ width: 370, mt: 1 }}
                  labelId="select-tipo_usuario"
                  id="select-tipo_usuario-id"
                  value={usuarioNew.tipo}
                  label="tipo"
                  onChange={setUsuarioNewValues("tipo")}
                >
                  <MenuItem key="0" value="1">
                    ADMINISTRADOR
                  </MenuItem>
                  <MenuItem key="1" value="2">
                    CAJERO
                  </MenuItem>
                  <MenuItem key="2" value="3">
                    MESERO
                  </MenuItem>
                </Select>
              </Item>

              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>FOTO (NO DISPONIBLE)</b>
                </Typography>
                <OutlinedInput
                  disabled
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  id="foto"
                  value=""
                  label="foto"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TELEFONO </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 9,
                  }}
                  id="telefono"
                  value={usuarioNew.telefono}
                  onChange={setUsuarioNewValues("telefono")}
                  label="telefono"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>MAC ADDRESS </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="mac"
                  value={usuarioNew.mac}
                  onChange={setUsuarioNewValues("mac")}
                  label="mac"
                />
              </Item>
            </Stack>
            <Stack
              sx={{
                width: 300,
                fontSize: 20,
                height: 100,
                ml: 2,
                mt: 2,
                mb: 2,
              }}
              spacing={1}
            >
              <Button
                sx={{ width: 200, fontSize: 20 }}
                size="large"
                onClick={() => {
                  insUpdUsuario(0);
                }}
                color="success"
                variant="contained"
                style={{ marginRight: 5 }}
              >
                GUARDAR
              </Button>
            </Stack>
          </div>
        )}
      </Box>

      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoadCocina}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={msgCocina}
            autoHideDuration={null}
          >
            <Alert severity={resultCocina}>{msgResultCocina}</Alert>
          </Snackbar>
        </Stack>
      </div>
      <div>
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
          <AppBar sx={{ position: "fixed", backgroundColor: "#63b899" }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                {idxUsuarioEdit >= 0 ? usuarioEdit.nombre : "NN"}
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ ml: 1, mt: 10, width: 390 }}>
            <Stack spacing={1}>
              <Typography
                sx={{ minWidth: 300, flex: "1 1 100%", mt: 1, ml: 1, mb: 1 }}
                variant="h7"
                id="tableTitle"
                component="div"
              >
                <b>EDITA AL USUARIO </b>
              </Typography>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE * </b>
                </Typography>
                <OutlinedInput
                  error={nombreError}
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="nombre"
                  value={usuarioEdit.nombre}
                  onChange={setUsuarioEditValue("nombre")}
                  label="nombre"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>APELLIDO *</b>
                </Typography>
                <OutlinedInput
                  error={apellidoError}
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="apellido"
                  value={usuarioEdit.apellido}
                  onChange={setUsuarioEditValue("apellido")}
                  label="apellido"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>RUT </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="rut"
                  value={usuarioEdit.rut === null ? "" : usuarioEdit.rut}
                  onChange={setUsuarioEditValue("rut")}
                  label="rut"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>USERNAME * </b>
                </Typography>
                <OutlinedInput
                  error={usernameError}
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="username"
                  value={usuarioEdit.username}
                  onChange={setUsuarioEditValue("username")}
                  label="username"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>PASSWORD </b>
                </Typography>
                <OutlinedInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={usuarioEdit.password}
                  onChange={setUsuarioEditValue("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TIPO USUARIO * </b>
                </Typography>
                <Select
                  sx={{ width: 370, mt: 1 }}
                  labelId="select-tipo_usuario"
                  id="select-tipo_usuario-id"
                  value={usuarioEdit.tipo}
                  label="tipo"
                  onChange={setUsuarioEditValue("tipo")}
                >
                  <MenuItem key="0" value="1">
                    ADMINISTRADOR
                  </MenuItem>
                  <MenuItem key="1" value="2">
                    CAJERO
                  </MenuItem>
                  <MenuItem key="2" value="3">
                    MESERO
                  </MenuItem>
                </Select>
              </Item>

              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>FOTO (NO DISPONIBLE)</b>
                </Typography>
                <OutlinedInput
                  disabled
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  id="foto"
                  value=""
                  label="foto"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TELEFONO </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 9,
                  }}
                  id="telefono"
                  value={
                    usuarioEdit.telefono === null ? "" : usuarioEdit.telefono
                  }
                  onChange={setUsuarioEditValue("telefono")}
                  label="telefono"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>MAC ADDRESS </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="mac"
                  value={usuarioEdit.mac === null ? "" : usuarioEdit.mac}
                  onChange={setUsuarioEditValue("mac")}
                  label="mac"
                />
              </Item>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                width: 300,
                fontSize: 20,
                height: 100,
                ml: 1,
                mt: 1,
                mb: 1,
              }}
            >
              <Button
                sx={{ width: 150, fontSize: 15, height: 50, mr: 2 }}
                size="large"
                onClick={() => {
                  insUpdUsuario(1);
                }}
                color="success"
                variant="contained"
              >
                GUARDAR
              </Button>
              <Button
                sx={{ width: 150, fontSize: 15, height: 50 }}
                size="large"
                onClick={() => {
                  setOpenDialog(false);
                }}
                color="error"
                variant="contained"
              >
                CANCELAR
              </Button>
            </Stack>
          </Box>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
