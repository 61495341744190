import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

const pages = [
  { menuName: "Jornada", menuPage: "JornadasAdmin" },
  { menuName: "Mesas", menuPage: "MesasAdmin" },
  { menuName: "Estacionamiento", menuPage: "EstacAdmin" },
  { menuName: "Productos", menuPage: "ProductosAdmin" },
  { menuName: "Ventas", menuPage: "VentasAdmin" },
  { menuName: "Usuarios", menuPage: "UsuariosAdmin" },
];
const settings = [
  { menuName: "Perfil", menuPage: "PerfilAdmin" },
  { menuName: "Salir", menuPage: "Logout" },
];

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

const AppbarMenu = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const idUsuario = sessionStorage.getItem("userid");
  const usertype = sessionStorage.getItem("usertype");
  const usuario = sessionStorage.getItem("nombre");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);

    if (event.currentTarget.id !== "") {
      let currJornada = sessionStorage.getItem("idJornada");

      if (parseInt(currJornada) <= 0) {
        Swal.fire({
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: "rgba(0,0,123,0.4)",
          title: "SIN JORNADA LABORAL",
          text: "¿Quieres abrir la jornada laboral?",
          showDenyButton: true,
          confirmButtonText: "SI",
          denyButtonText: "NO",
          icon: "question",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/JornadasAdmin";
          } else {
            window.location.href = "/MesasAdmin";
          }
        });
      } else {
        window.location.href = "/" + event.currentTarget.id;
      }
    }
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);

    if (event.currentTarget.id === "Logout") logout();
    else if (event.currentTarget.id !== "") {
      let currJornada = sessionStorage.getItem("idJornada");

      if (parseInt(currJornada) <= 0) {
        Swal.fire({
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: "rgba(0,0,123,0.4)",
          title: "SIN JORNADA LABORAL",
          text: "¿Quieres abrir la jornada laboral?",
          showDenyButton: true,
          confirmButtonText: "SI",
          denyButtonText: "NO",
          icon: "question",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/JornadasAdmin";
          } else {
            window.location.href = "/MesasAdmin";
          }
        });
      } else {
        window.location.href = "/" + event.currentTarget.id;
      }
    }
  };

  return (
    <AppBar sx={{ top: 0, height: 70 }} position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            ADMINISTRADOR |
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.menuName}
                  onClick={handleCloseNavMenu}
                  id={page.menuPage}
                >
                  <Typography textAlign="center">{page.menuName}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            ADMINISTRADOR
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.menuName}
                onClick={handleCloseNavMenu}
                id={page.menuPage}
                sx={{
                  my: 2,
                  mr: 2,
                  bgcolor: "#3ca1bd",
                  boxShadow: "2",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#999797", // theme.palette.primary.main
                    color: "white",
                  },
                  display: "block",
                }}
              >
                {page.menuName}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={usuario} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.menuName}
                  onClick={handleCloseUserMenu}
                  id={setting.menuPage}
                >
                  <Typography textAlign="center">{setting.menuName}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppbarMenu;
