import React, { useEffect } from "react";
import "./css/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./Signin";
import Mesas from "./Mesas";
import MenuMesa from "./MenuMesa";
import Cuenta from "./Cuenta";
import DatosPago from "./DatosPago";
import MesasAdmin from "./MesasAdmin";
import JornadasAdmin from "./JornadasAdmin";
import EstacAdmin from "./EstacAdmin";
import ProductosAdmin from "./ProductosAdmin";
import UsuariosAdmin from "./UsuariosAdmin";
import HomeAdmin from "./HomeAdmin";

function App() {


  const token = sessionStorage.getItem("accessToken");
  const usertype = sessionStorage.getItem("usertype");


  if (!token) {
    return <Signin />;
  } else if (parseInt(usertype) === 3) {
    return <Signin />;
  }


  if (parseInt(usertype) !== 1) {
    
    return (
      <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route path="Mesas" element={<Mesas />}></Route>
            <Route path="/" element={<Mesas />}></Route>
            <Route path="MenuMesa" element={<MenuMesa />}></Route>
            <Route path="Cuenta" element={<Cuenta />}></Route>
            <Route path="DatosPago" element={<DatosPago />}></Route>
            <Route path="EstacAdmin" element={<EstacAdmin />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  } else if (parseInt(usertype) === 1) {
    
    return (
      <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route path="MesasAdmin" element={<MesasAdmin />}></Route>
            <Route path="/" element={<HomeAdmin />}></Route>
            <Route path="HomeAdmin" element={<HomeAdmin />}></Route>
            <Route path="JornadasAdmin" element={<JornadasAdmin />}></Route>
            <Route path="EstacAdmin" element={<EstacAdmin />}></Route>
            <Route path="ProductosAdmin" element={<ProductosAdmin />}></Route>
            <Route path="UsuariosAdmin" element={<UsuariosAdmin />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
