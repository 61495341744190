import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Fab from "@mui/material/Fab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import configData from "./config.json";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ItemCuentaPedido = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemCuentaHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  backgroundColor: "#18544d",
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function backToMenuMesa() {
  sessionStorage.removeItem("currentOper");
  window.location.href = "/MenuMesa";
}

function backToMesas() {
  sessionStorage.removeItem("currentOper");
  sessionStorage.removeItem("currentMesa");
  sessionStorage.removeItem("nombreMesa");

  window.location.href = "/Mesas";
}

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

function toNumber(value) {
  if (isNaN(parseInt(value, 10))) {
    return 0;
  } else return parseInt(value, 10);
}

async function getCuenta(idUsuario, token, idMesa, cabdet) {
  const data = await fetch(configData.getCuenta, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({ idMesa: idMesa, cabdet: cabdet }),
  });
  return await data.json();
}

async function solicitarCuentaExtra(
  idUsuario,
  token,
  idMesa,
  extraCost,
  descuento,
  comentario
) {
  const data = await fetch(configData.solicitarCuentaExtra, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idMesa: idMesa,
      idUsuario: idUsuario,
      extraCost: extraCost,
      descuento: descuento,
      comentario: comentario,
    }),
  });
  return await data.json();
}

export default function Cuenta(props) {
  const cuentaTotalSch = {
    id: 0,
    subtotal: 0,
    extra: 0,
    descuento: 0,
    comentario: "",
    total: 0,
  };

  /*const cuentaDetalleSch = [
    {
      id: 0,
      producto: "",
      cantidad: 0,
      precio: 7500,
      total: 7500,
    }
  ];*/

  //-------------------------------------------
  const usuario = sessionStorage.getItem("nombre");
  const currentMesa = sessionStorage.getItem("currentMesa");
  const nombreMesa = sessionStorage.getItem("nombreMesa");
  const currentOper = sessionStorage.getItem("currentOper");

  const [show, setShow] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [msg, setMsg] = React.useState();

  const [menor0, setMenor0] = React.useState(false);
  const [errorColor, setErrorColor] = React.useState("#000000");
  const [isLoading, setIsLoading] = useState(true);

  const [cuentaTotal, setCuentaTotal] = React.useState(cuentaTotalSch);
  const [cuentaDetalle, setCuentaDetalle] = React.useState([]);

  const timer = React.useRef();

  const [values, setValues] = React.useState({
    descuento: "",
    extra: "",
    comentario: "",
  });

  const handleChange = (prop) => (event) => {
    var Suma = 0;

    if (prop !== "comentario") {
      if (isNaN(event.target.value)) {
        return null;
      } else setValues({ ...values, [prop]: event.target.value });

      if (prop === "extra")
        Suma =
          toNumber(cuentaTotal.subtotal) +
          toNumber(event.target.value) -
          toNumber(values.descuento);
      else
        Suma =
          toNumber(cuentaTotal.subtotal) +
          toNumber(values.extra) -
          toNumber(event.target.value);

      if (Suma <= 0) {
        setMenor0(true);
        setErrorColor("#ff0000");
        Swal.fire({
          title: "TOTAL INVALIDO",
          text: "El total de la cuenta no puede ser 0 o menor a 0...",
          icon: "error",
        });
      } else {
        setErrorColor("#000000");
        setMenor0(false);
      }
      setCuentaTotal({
        ...cuentaTotal,
        [prop]: toNumber(event.target.value),
        total: Suma,
      });
    } else {
      setValues({ ...values, [prop]: event.target.value });
      setCuentaTotal({
        ...cuentaTotal,
        comentario: event.target.value,
      });
    }
  };

  const borraTodo = () => {
    setValues({ ...values, extra: "", descuento: "", comentario: "" });

    setCuentaTotal({
      ...cuentaTotal,
      comentario: "",
      extra: 0,
      descuento: 0,
      total: cuentaTotal.subtotal,
    });
  };

  const emitirBoleta = () => {
    if (!show) {
      setSeverity("info");
      setMsg("Emitiendo cuenta...");

      setShow(true);
      setLoading(true);
      setDialog(true);

      let idUsuario = sessionStorage.getItem("userid");
      let token = sessionStorage.getItem("accessToken");

      solicitarCuentaExtra(
        idUsuario,
        token,
        currentMesa,
        cuentaTotal.extra,
        cuentaTotal.descuento,
        cuentaTotal.comentario
      ).then(
        (result) => {
          if ("error" in result) logout();

          timer.current = window.setTimeout(() => {
            setShow(false);
            setLoading(false);
            setDialog(false);
      
            if (result.codigo === 0) {
              Swal.fire({
                title: "CUENTA EMITIDA",
                text: "Espere la impresión y entregue al cliente...",
                icon: "success",
              }).then((result) => {
                backToMesas();
              });
            } else
              Swal.fire({
                title: "ERROR",
                text: result.message,
                icon: "error",
              });

            //setMsgCocina(true);
          }, 2000);
        },

        (error) => {
          setShow(false);
          setLoading(false);
          setDialog(false);

          Swal.fire({
            title: "ERROR",
            text: error,
            icon: "error",
          });
        }
      );
    }
  };

  useEffect(() => {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    //Cabecera
    getCuenta(idUsuario, token, currentMesa, 0).then(
      (result) => {
        if ("error" in result) logout();
        setCuentaTotal(result);

        getCuenta(idUsuario, token, currentMesa, 1).then(
          (result) => {
            if ("error" in result) logout();
            setCuentaDetalle(result);
          },

          (error) => {
            setIsLoading(false);

            Swal.fire({
              title: "ERROR",
              text: error,
              icon: "error",
            });
          }
        );
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      <CssBaseline />
      <ElevationScroll {...props}>
      <AppBar color="primary" style={{ height: "100px", maxHeight: "100px" }}>
          <Toolbar style={{ height: "100px", maxHeight: "100px" }}>
            <Grid
              sx={{ mt: 1 }}
              container
              justifyContent="flex-start"
              width="500px"
            >
              <Avatar
                sx={{ width: 32, height: 32, mr: 1 }}
                alt={usuario}
                src="/static/images/avatar/1.jpg"
              />
              <Chip
                sx={{ color: "white", fontSize: 20 }}
                label={usuario}
                variant="filled"
                size="medium"
              />
              <Typography
                variant="h5"
                component="div"
                sx={{ mt: 2, flexGrow: 1, width: '500px' }}
              >
                DETALLE CUENTA PARA - {nombreMesa}
              </Typography>
            </Grid>
            <Grid sx={{ mt: 1 }} container justifyContent="flex-end">
              <Button
                size="small"
                onClick={() => backToMesas()}
                color="error"
                variant="contained"
              >
                Cancelar
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box
        sx={{
          p: 1,
          flexGrow: 1,
          backgroundColor: "white",
          mt: 15,
          display: "flex",
          "& > *": {
            m: 1,
          },
        }}
        spacing={2}
        direction="row"
        //alignItems="center"
        justifyContent="left"
        //style={{ maxHeight: 55 }}
      >
        <TableContainer
          sx={{ maxWidth: 700, maxHeight: 1000 }}
          component={Paper}
        >
          <Table
            sx={{ minWidth: 600, maxHeight: 1000 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    <b>PEDIDOS:</b>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"><b>PLATO</b></TableCell>
                <TableCell align="right"><b>CANTIDAD</b></TableCell>
                <TableCell align="right"><b>PRECIO U.</b></TableCell>
                <TableCell align="right"><b>TOTAL</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cuentaDetalle.map((detalle) => (
                <TableRow
                  key={detalle.id_pedido}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell  sx={{ width: "350px"}} component="th" scope="row" align="left">
                    
                    {detalle.agregado === null
                      ? detalle.producto
                      : detalle.producto + " (" + detalle.agregado + 
                      
                      ((detalle.precio_agregado === null || detalle.precio_agregado === 0)
                        ? ""
                        : " $" + detalle.precio_agregado
                      ) + ")"}
                  </TableCell>
                  <TableCell sx={{ width: "150px"}} align="right">{detalle.cantidad}</TableCell>
                  <TableCell sx={{ width: "150px"}} align="right">{detalle.precio_unitario}</TableCell>
                  <TableCell sx={{ width: "150px"}} align="right">{detalle.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          sx={{ maxWidth: 450, maxHeight: 600 }}
          component={Paper}
        >
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell key="cell01">
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    <b>TOTALES:</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell key="cell02">
                  <b>SUB TOTAL</b>
                </TableCell>
                <TableCell key="cell03" align="left">
                  {cuentaTotal.subtotal}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell04">Agregar Extra</TableCell>
                <TableCell key="cell05" align="left">
                  <OutlinedInput
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                    id="extra"
                    value={values.extra}
                    size="small"
                    onChange={handleChange("extra")}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    label="Extra"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell06">Agregar Descuento</TableCell>
                <TableCell key="cell07" align="left">
                  <OutlinedInput
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                    id="descuento"
                    error={menor0}
                    value={values.descuento}
                    size="small"
                    onChange={handleChange("descuento")}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    label="descuento"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell08">
                  <b>TOTAL</b>
                </TableCell>
                <TableCell
                  key="cell09"
                  sx={{ color: errorColor }}
                  align="left"
                >
                  <b>{cuentaTotal.total}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell10">Agregar Comentario</TableCell>
                <TableCell key="cell11" align="left">
                  <OutlinedInput
                    inputProps={{
                      maxLength: 60,
                    }}
                    id="comentario"
                    value={values.comentario}
                    onChange={handleChange("comentario")}
                    label="comentario"
                    style={{ width: 250 }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell12" colSpan={2} spacing={2} align="right">
                  <Button
                    size="large"
                    onClick={() => borraTodo()}
                    color="error"
                    variant="contained"
                    style={{ marginRight: 5 }}
                  >
                    Borrar
                  </Button>
                  <Button
                    size="large"
                    onClick={() => emitirBoleta()}
                    color="success"
                    variant="contained"
                    disabled={menor0}
                  >
                    Emitir
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={show}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={dialog}
          >
            <Alert severity={severity}>{msg}</Alert>
          </Snackbar>
        </Stack>
      </div>
    </React.Fragment>
  );
}
