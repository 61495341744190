import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Fab from "@mui/material/Fab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Select from "@mui/material/Select";
import configData from "./config.json";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ItemCuentaPedido = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemCuentaHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  backgroundColor: "#18544d",
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function backToMenuMesa() {
  sessionStorage.removeItem("currentOper");
  window.location.href = "/MenuMesa";
}

function backToMesas() {
  sessionStorage.removeItem("currentOper");
  sessionStorage.removeItem("currentMesa");
  sessionStorage.removeItem("nombreMesa");

  window.location.href = "/Mesas";
}

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

function toNumber(value) {
  if (isNaN(parseInt(value, 10))) {
    return 0;
  } else return parseInt(value, 10);
}

async function setDatosPagoAPI(idUsuario, token, datos) {
  const data = await fetch(configData.setDatosPago, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      datos,
    }),
  });
  return await data.json();
}

export default function DatosPago(props) {
  const datosPagoSch = {
    idMesa: 0,
    idUsuario: 0,
    formaPago: "",
    montoPagado: 0,
    comentario: "",
    propina: 0,
  };

  /*const cuentaDetalleSch = [
    {
  "idMesa": 1,
  "idUsuario": 2,
  "formaPago": "Efectivo",
  "montoPagado": 40000,
  "comentario": "sin",
  "propina": 8000
    }
  ];*/

  //-------------------------------------------
  const usuario = sessionStorage.getItem("nombre");
  const currentMesa = sessionStorage.getItem("currentMesa");
  const nombreMesa = sessionStorage.getItem("nombreMesa");
  const currentOper = sessionStorage.getItem("currentOper");
  

  const [show, setShow] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [msg, setMsg] = React.useState();

  const [menor0, setMenor0] = React.useState(false);
  const [errorColor, setErrorColor] = React.useState("#000000");
  const [isLoading, setIsLoading] = useState(false);

  const [datosPago, setDatosPago] = React.useState(datosPagoSch);

  const timer = React.useRef();

  const [values, setValues] = React.useState({
    formaPago: "",
    montoPagado: "",
    propina: "",
    comentario: "",
  });

  const handleChange = (prop) => (event) => {
    var Suma = 0;

    if (prop !== "comentario" && prop !== "formaPago") {
      if (isNaN(event.target.value)) {
        return null;
      } else setValues({ ...values, [prop]: event.target.value });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const borraTodo = () => {
    setValues({ ...values, formaPago: "", montoPagado: "", comentario: "", propina: "" });

    setDatosPago({
      ...datosPago,
      comentario: "",
      propina: 0,
      formaPago: "",
      montoPagado: 0,
    });
  };

  const enviarDatosPago = () => {

    if ((values.formaPago === "") || (parseInt(values.montoPagado) === 0) || (parseInt(values.propina) === 0)){
      Swal.fire({
        title: "ERROR",
        text: "Debe ingresar la información",
        icon: "error",
      });
    
      return null;
    }

    if (!show) {
      setSeverity("info");
      setMsg("Guardando datos del pago...");

      setShow(true);
      setLoading(true);
      setDialog(true);

      let idUsuario = sessionStorage.getItem("userid");
      let token = sessionStorage.getItem("accessToken");


      datosPago.comentario = (values.comentario === "") ? "" : values.comentario;
      datosPago.propina = parseInt(values.propina);
      datosPago.formaPago = values.formaPago;
      datosPago.montoPagado = parseInt(values.montoPagado);
      datosPago.idMesa = currentMesa;
      datosPago.idUsuario = idUsuario;

      setDatosPagoAPI(idUsuario, token, datosPago).then(
        (result) => {
         

          timer.current = window.setTimeout(() => {
            setShow(false);
            setLoading(false);
            setDialog(false);

            if ("error" in result) logout();

            if (result.codigo === 0) {
              Swal.fire({
                title: "DATOS GUARDADOS",
                text: "Se registraron correctamente los datos del pago...",
                icon: "success",
              }).then((result) => {
                backToMesas();
              });
            } else
              Swal.fire({
                title: "ERROR",
                text: result.message,
                icon: "error",
              });

            //setMsgCocina(true);
          }, 2000);
        },

        (error) => {
          setShow(false);
          setLoading(false);
          setDialog(false);

          Swal.fire({
            title: "ERROR",
            text: error,
            icon: "error",
          });
        }
      );
    }
  };

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar color="primary" style={{ height: "100px", maxHeight: "100px" }}>
          <Toolbar style={{ height: "100px", maxHeight: "100px" }}>
            <Grid
              sx={{ mt: 1 }}
              container
              justifyContent="flex-start"
              width="500px"
            >
              <Avatar
                sx={{ width: 32, height: 32, mr: 1 }}
                alt={usuario}
                src="/static/images/avatar/1.jpg"
              />
              <Chip
                sx={{ color: "white", fontSize: 20 }}
                label={usuario}
                variant="filled"
                size="medium"
              />
              <Typography
                variant="h5"
                component="div"
                sx={{ mt: 2, flexGrow: 1, width: "500px" }}
              >
                INGRESA DATOS DE PAGO PARA - {nombreMesa}
              </Typography>
            </Grid>
            <Grid sx={{ mt: 1 }} container justifyContent="flex-end">
              <Button
                size="small"
                onClick={() => backToMesas()}
                color="error"
                variant="contained"
              >
                Cancelar
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box
        sx={{
          p: 1,
          flexGrow: 1,
          backgroundColor: "white",
          mt: 15,
          display: "flex",
          "& > *": {
            m: 1,
          },
        }}
        spacing={2}
        direction="row"
        //alignItems="center"
        justifyContent="left"
        //style={{ maxHeight: 55 }}
      >
        <TableContainer
          sx={{ maxWidth: 500, maxHeight: 600 }}
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell key="cell01">
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    <b>DATOS DE PAGO:</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell key="cell04">Forma de Pago</TableCell>
                <TableCell key="cell05" align="left">
                  <Select
                    sx={{ width: 200 }}
                    labelId="select-agregado-gratis"
                    id="select-agregado-gratis-id"
                    value={values.formaPago}
                    label="formaPago"
                    onChange={handleChange("formaPago")}
                  >
                    <MenuItem key="0" value="Debito">
                      Debito
                    </MenuItem>
                    <MenuItem key="1" value="Credito">
                      Credito
                    </MenuItem>
                    <MenuItem key="2" value="Efectivo">
                      Efectivo
                    </MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell06">Monto Cancelado</TableCell>
                <TableCell key="cell07" align="left">
                  <OutlinedInput
                    sx={{ width: 200 }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                    id="descuento"
                    error={menor0}
                    value={values.montoPagado}
                    size="small"
                    onChange={handleChange("montoPagado")}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    label="descuento"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell16">Propina</TableCell>
                <TableCell key="cell17" align="left">
                  <OutlinedInput
                    sx={{ width: 200 }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]" }}
                    id="descuento"
                    error={menor0}
                    value={values.propina}
                    size="small"
                    onChange={handleChange("propina")}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    label="descuento"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell10">Comentario</TableCell>
                <TableCell key="cell11" align="left">
                  <OutlinedInput
                    sx={{ width: 200 }}
                    inputProps={{
                      maxLength: 60,
                    }}
                    id="comentario"
                    value={values.comentario}
                    onChange={handleChange("comentario")}
                    label="comentario"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell key="cell12" colSpan={2} spacing={2} align="right">
                  <Button
                    size="large"
                    onClick={() => borraTodo()}
                    color="error"
                    variant="contained"
                    style={{ marginRight: 5 }}
                  >
                    Borrar
                  </Button>
                  <Button
                    size="large"
                    onClick={() => enviarDatosPago()}
                    color="success"
                    variant="contained"
                    disabled={menor0}
                  >
                    Guardar
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={show}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={dialog}
          >
            <Alert severity={severity}>{msg}</Alert>
          </Snackbar>
        </Stack>
      </div>
    </React.Fragment>
  );
}
