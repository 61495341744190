import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Fab from "@mui/material/Fab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Select from "@mui/material/Select";
import configData from "./config.json";
import AppbarMenu from "./AppbarMenu";
import AddIcon from "@mui/icons-material/Add";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ItemCuentaPedido = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemCuentaHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  backgroundColor: "#18544d",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function backToMenuMesa() {
  sessionStorage.removeItem("currentOper");
  window.location.href = "/MenuMesa";
}

function backToMesas() {
  sessionStorage.removeItem("currentOper");
  sessionStorage.removeItem("currentMesa");
  sessionStorage.removeItem("nombreMesa");

  window.location.href = "/Mesas";
}

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

function toNumber(value) {
  if (isNaN(parseInt(value, 10))) {
    return 0;
  } else return parseInt(value, 10);
}

function getLocalDate(inDate) {
  let auxDate = new Date(inDate);

  return (auxDate.toLocaleString(configData.localeDate, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }) ) + " " + auxDate.getHours() + ":" + auxDate.getMinutes();
}

async function getTicket(idUsuario, token, inPatente) {
  const data = await fetch(configData.getTicketEstacionamiento, {
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({ idUsuario: idUsuario, inPatente: inPatente }),
  });
  return await data.json();
}

export default function EstacAdmin(props) {

  //-------------------------------------------
  const usuario = sessionStorage.getItem("nombre");
  const currentMesa = sessionStorage.getItem("currentMesa");
  const nombreMesa = sessionStorage.getItem("nombreMesa");
  const currentOper = sessionStorage.getItem("currentOper");
 

  const [show, setShow] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [msg, setMsg] = React.useState();

  const [inPatente, setInPatente] = React.useState("");

  const [isLoading, setIsLoading] = useState(false);

  const timer = React.useRef();


  const setPatente = (prop) => (event) => {
    
    var letterNumber = /^[0-9a-zA-Z]+$/;
    
    if (event.target.value.match(letterNumber) || event.target.value === "" ) 
       setInPatente(String(event.target.value).toUpperCase());

  };

  const generaTicket = () => {

    if (inPatente === ""){
      Swal.fire({
        title: "ERROR",
        text: "Debe ingresar la patente",
        icon: "error",
      });
    
      return null;
    }

    if (!show) {
      setSeverity("info");
      setMsg("Generando ticket estacionamiento...");

      setShow(true);
      setLoading(true);
      setDialog(true);

      let idUsuario = sessionStorage.getItem("userid");
      let token = sessionStorage.getItem("accessToken");

      getTicket(idUsuario, token, inPatente).then(
        (result) => {
         
          timer.current = window.setTimeout(() => {
            setShow(false);
            setLoading(false);
            setDialog(false);

            if ("error" in result) logout();

            if (result.codigo === 0) {
              Swal.fire({
                title: "TICKET GENERADO",
                text: "NUMERO TICKET: " + result.ticket + "... Ve por el a la impresora.",
                icon: "success",
              }).then((result) => {
                setInPatente("");
              });
            } else
              Swal.fire({
                title: "ERROR",
                text: result.message,
                icon: "error",
              });

          }, 2000);
        },

        (error) => {
          setShow(false);
          setLoading(false);
          setDialog(false);

          Swal.fire({
            title: "ERROR",
            text: error,
            icon: "error",
          });
        }
      );
    }
  };

  useEffect(() => {

    let currJornada = sessionStorage.getItem("idJornada");
    
    if (parseInt(currJornada) <= 0) {
      Swal.fire({
        showCloseButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: "rgba(0,0,123,0.4)",
        title: "SIN JORNADA LABORAL",
        text: "¿Quieres abrir la jornada laboral?",
        showDenyButton: true,
        confirmButtonText: "SI",
        denyButtonText: "NO",
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/JornadasAdmin";
        } else if (result.isDenied) {
          window.location.href = "/MesasAdmin";
        }
      });
    } 


  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      {AppbarMenu()}

      <Box
        sx={{
          p: 1,
          flexGrow: 1,
          flexDirection: "column",
          backgroundColor: "white",
          mt: 10,
          display: "flex",
          "& > *": {
            m: 1,
          },
        }}
        key="box-space"
        component="span"
        spacing={2}
        alignItems="left"
      >
        <Stack
          sx={{ minWidth: 300, maxWidth: 600 }}
          direction="column"
          spacing={1}
        >
          <Typography
            sx={{ minWidth: 300, flex: "1 1 100%", mt: 1, ml: 1, mb: 1 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <b>TICKET PARA ESTACIONAMIENTO</b>
          </Typography>
          <Item>
            Hoy es:{" "}
            {new Date().toLocaleString(configData.localeDate, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Item>
          <Item>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, width: 300 }}
            >
              <b>INGRESA LA PATENTE: </b>
            </Typography>
            <OutlinedInput
                    sx={{ width: 200, mt: 1, fontSize: 30 }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    id="comentario"
                    value={inPatente}
                    onChange={setPatente()}
                    label="comentario"
                  />
          </Item>
        </Stack>
  
        <Stack sx={{ width: 200, fontSize: 20}} spacing={1}>
          <Button
            sx={{ width: 300, fontSize: 20}}
            size="large"
            onClick={() => generaTicket()}
            color="success"
            variant="contained"
            style={{ marginRight: 5 }}
          >
            GENERA TICKET
          </Button>
        </Stack>
      </Box>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={show}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={dialog}
          >
            <Alert severity={severity}>{msg}</Alert>
          </Snackbar>
        </Stack>
      </div>
     
    </React.Fragment>
  );
}
