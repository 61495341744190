import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import Fab from "@mui/material/Fab";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditIcon from "@mui/icons-material/Edit";
import configData from "./config.json";
import AppbarMenu from "./AppbarMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OutlinedInput from "@mui/material/OutlinedInput";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemPedido = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  backgroundColor: "#18544d",
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function backToMenuMesa() {
  sessionStorage.removeItem("currentOper");
  window.location.href = "/MenuMesa";
}

function backToMesas() {
  sessionStorage.removeItem("currentOper");
  sessionStorage.removeItem("currentMesa");
  sessionStorage.removeItem("nombreMesa");

  window.location.href = "/Mesas";
}

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

async function getProductos(idUsuario, token) {
  const data = await fetch(configData.getProductos, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
  });
  return await data.json();
}

async function setProductoEstado(idUsuario, token, idProducto, inEstado) {
  const data = await fetch(configData.setProductoEstado, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idUsuario: idUsuario,
      idProducto: idProducto,
      inEstado: inEstado,
    }),
  });
  return await data.json();
}

async function setProductoAPI(idUsuario, token, productoInsUpd) {
  const data = await fetch(configData.setProducto, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idUsuario: idUsuario,
      producto: productoInsUpd,
    }),
  });
  return await data.json();
}

async function enviaCocina(idUsuario, token, idMesa, pedidosBody) {
  const data = await fetch(configData.addPedido, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idMesa: idMesa,
      idUsuario: idUsuario,
      pedidos: pedidosBody,
    }),
  });
  return await data.json();
}

export default function ProductosAdmin(props) {
  const prodcutoNewSch = {
    id: -1,
    precio_costo: "",
    precio_venta: "",
    nombre_en_carta: "",
    stock: "",
    marca_producto: "",
    nombre_producto: "",
    tipo_producto: "ENTRADAS",
    categoria: 0,
    agregado_gratis: 0,
    agregado_liquido: 0,
    nombre_comanda: "",
    nombre_boleta: "",
    subtitle_nombre_carta: "",
    estado: 1,
  };
  //-------------------------------------------

  const usuario = sessionStorage.getItem("nombre");
  const currentMesa = sessionStorage.getItem("currentMesa");
  const nombreMesa = sessionStorage.getItem("nombreMesa");

  //const currentOper = sessionStorage.getItem("currentOper");

  const timer = React.useRef();
  const [value, setValue] = React.useState(0);
  const [showLoadCocina, setShowLoadCocina] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [msgCocina, setMsgCocina] = React.useState(false);
  const [resultCocina, setResultCocina] = React.useState();
  const [msgResultCocina, setMsgResultCocina] = React.useState();

  const [productos, setProductos] = React.useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [openDialog, setOpenDialog] = React.useState(false);


  const [listaPedidosArray, setListaPedidosArray] = React.useState([]);

  const [idxProdEdit, setIdxProdEdit] = React.useState(-1);
  const [productoNew, setProductoNew] = React.useState(prodcutoNewSch);
  const [productoEdit, setProductoEdit] = React.useState({});

  function TituloComida(props) {
    const { value, index } = props;

    var show = "none";
    var title = ".";

    if (index === 0) {
      show = "flex";
      title = productos[index].tipo_producto;
    } else {
      if (
        productos[index - 1].tipo_producto !== productos[index].tipo_producto
      ) {
        show = "flex";
        title = productos[index].tipo_producto;
      }
    }

    return (
      <div>
        <Chip
          sx={{
            mt: 1,
            display: show,
            minWidth: "100%",
          }}
          size="small"
          label={title}
          variant="filled"
          color="info"
        />
      </div>
    );
  }

  const handleChangeTabsMenu = (event, newValue) => {
    setValue(newValue);
    setProductoNew(prodcutoNewSch);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const setProductoNewValues = (prop) => (event) => {
    if (
      prop === "precio_venta" ||
      prop === "precio_costo" ||
      prop === "stock"
    ) {
      if (isNaN(event.target.value)) {
        return null;
      } else setProductoNew({ ...productoNew, [prop]: event.target.value });
    } else if (prop === "tipo_producto") {
      let textTipoProducto = getTipoProductoByID(parseInt(event.target.value));
      setProductoNew({ ...productoNew, [prop]: textTipoProducto });
    } else {
      setProductoNew({ ...productoNew, [prop]: event.target.value });
    }
  };

  const setProductEditValue = (prop) => (event) => {
    if (
      prop === "precio_venta" ||
      prop === "precio_costo" ||
      prop === "stock"
    ) {
      if (isNaN(event.target.value)) {
        return null;
      } else setProductoEdit({ ...productoEdit, [prop]: event.target.value });
    } else if (prop === "tipo_producto") {
      let textTipoProducto = getTipoProductoByID(parseInt(event.target.value));
      setProductoEdit({ ...productoEdit, [prop]: textTipoProducto });
    } else {
      setProductoEdit({ ...productoEdit, [prop]: event.target.value });
    }
  };

  const getCategoriaByText = (idTipoProd) => {
    switch (idTipoProd) {
      case "ENTRADAS":
        return 0;

      case "PLATOS":
        return 0;

      case "VEGETARIANO":
        return 0;

      case "ENSALADAS":
        return 0;

      case "AGREGADO EXTRA":
        return 0;

      case "AGREGADO GRATIS":
        return 2;

      case "APERITIVOS":
        return 1;

      case "VINOS":
        return 1;

      case "CERVEZAS":
        return 1;

      case "BEBIDAS":
        return 1;

      case "POSTRES":
        return 1;

      case "AGREGADO LIQUIDO":
        return 3;

      case "JUGOS NATURALES":
        return 1;

      case "AGUAS":
        return 1;

      default:
        return -1;
    }
  };

  const getTipoProductoByText = (idTipoProd) => {
    switch (idTipoProd) {
      case "ENTRADAS":
        return 1;

      case "PLATOS":
        return 2;

      case "VEGETARIANO":
        return 3;

      case "ENSALADAS":
        return 4;

      case "AGREGADO EXTRA":
        return 5;

      case "AGREGADO GRATIS":
        return 6;

      case "APERITIVOS":
        return 7;

      case "VINOS":
        return 8;

      case "CERVEZAS":
        return 9;

      case "BEBIDAS":
        return 10;

      case "POSTRES":
        return 11;

      case "AGREGADO LIQUIDO":
        return 12;

      case "JUGOS NATURALES":
        return 13;

      case "AGUAS":
        return 14;

      default:
        return -1;
    }
  };

  const getTipoProductoByID = (idTipoProd) => {
    switch (idTipoProd) {
      case 1:
        return "ENTRADAS";

      case 2:
        return "PLATOS";

      case 3:
        return "VEGETARIANO";

      case 4:
        return "ENSALADAS";

      case 5:
        return "AGREGADO EXTRA";

      case 6:
        return "AGREGADO GRATIS";

      case 7:
        return "APERITIVOS";

      case 8:
        return "VINOS";

      case 9:
        return "CERVEZAS";

      case 10:
        return "BEBIDAS";

      case 11:
        return "POSTRES";

      case 12:
        return "AGREGADO LIQUIDO";

      case 13:
        return "JUGOS NATURALES";

      case 14:
        return "AGUAS";

      default:
        return "";
    }
  };

  const insUpdProducto = (inOper) => {
    setOpenDialog(false);

    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    setShowLoadCocina(true);
    setLoading(true);

    setResultCocina("info");
    setMsgResultCocina(
      inOper === 0 ? "Creando Producto..." : "Actualizando Producto..."
    );
    setMsgCocina(true);

    if (inOper === 0)
      productoNew.categoria = getCategoriaByText(productoNew.tipo_producto);
    else if (inOper === 1)
      productoEdit.categoria = getCategoriaByText(productoEdit.tipo_producto);

    //Actualiza o crea el producto
    setProductoAPI(
      idUsuario,
      token,
      inOper === 0 ? productoNew : productoEdit
    ).then(
      (result) => {
        if ("error" in result) logout();

        timer.current = window.setTimeout(() => {
          setMsgCocina(false);
          setLoading(false);
          setShowLoadCocina(false);

          if (result.codigo === 0 || result.codigo === 1) {
            Swal.fire({
              title:
                inOper === 0
                  ? productoNew.nombre_producto
                  : productoEdit.nombre_producto,
              text: inOper === 0 ? "CREADO" : "ACTUALIZADO",
              icon: "success",
            }).then((result) => {
              window.location.href = "/ProductosAdmin";
            });
          } else
            Swal.fire({
              title: "ERROR",
              text: result.message,
              icon: "error",
            });
        }, 2000);
      },

      (error) => {
        setMsgCocina(false);
        setLoading(false);
        setShowLoadCocina(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );
  };

  const borraPedidos = () => {
    if (listaPedidosArray !== null && listaPedidosArray.length > 0) {
      setIsLoading(true);

      setListaPedidosArray([]);

      timer.current = window.setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const editaProducto = (idx) => {
    setProductoEdit(productos[idx]);

    setIdxProdEdit(idx);

    setOpenDialog(true);
  };

  const setEnableDisableProd = (idx) => {
    Swal.fire({
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: "rgba(0,0,123,0.4)",
      title: String(productos[idx].nombre_producto).toUpperCase(),
      text: productos[idx].estado === 0 ? "ACTIVARLO?" : "DESACTIVARLO?",
      showDenyButton: true,
      confirmButtonText: "SI",
      denyButtonText: "NO",
      icon: "question",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        let idUsuario = sessionStorage.getItem("userid");
        let token = sessionStorage.getItem("accessToken");

        setProductoEstado(
          idUsuario,
          token,
          productos[idx].id,
          productos[idx].estado === 0 ? 1 : 0
        ).then(
          (result) => {
            timer.current = window.setTimeout(() => {
              if ("error" in result) logout();
              getProductos(idUsuario, token).then(
                (result) => {
                  setIsLoading(false);
                  if ("error" in result) logout();
                  setProductos(result);

                  Swal.fire({
                    title: String(productos[idx].nombre_producto).toUpperCase(),
                    text: "ACTUALIZADO",
                    icon: "success",
                  });
                },

                (error) => {
                  setIsLoading(false);

                  Swal.fire({
                    title: "ERROR",
                    text: error,
                    icon: "error",
                  });
                }
              );
            }, 500);
          },

          (error) => {
            setIsLoading(false);

            Swal.fire({
              title: "ERROR",
              text: error,
              icon: "error",
            });
          }
        );
      }
    });
  };

  useEffect(() => {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    getProductos(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setProductos(result);
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);

      let currJornada = sessionStorage.getItem("idJornada");

      if (parseInt(currJornada) <= 0) {
        Swal.fire({
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: "rgba(0,0,123,0.4)",
          title: "SIN JORNADA LABORAL",
          text: "¿Quieres abrir la jornada laboral?",
          showDenyButton: true,
          confirmButtonText: "SI",
          denyButtonText: "NO",
          icon: "question",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/JornadasAdmin";
          } else {
            window.location.href = "/MesasAdmin";
          }
        });
      }
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      {AppbarMenu()}
      <div>
        <Box
          sx={{
            p: 1,
            bgcolor: "white",
            flexDirection: "row",
            backgroundColor: "white",
            display: "flex",
            borderBottom: 1,
            borderColor: "primary.main",
            maxWidth: 600,
            minWidth: 390,
            zIndex: 1000,
            top: 0,
            mt: 8.8,
          }}
          background-color="white"
          position="fixed"
          key="box-space"
          spacing={2}
          alignItems="left"
        >
          <Chip
            sx={{ mt: 1, mr: 1 }}
            size="large"
            label=" PRODUCTOS"
            variant="filled"
            color="info"
            width="100%"
          />
          <Tabs
            value={value}
            onChange={handleChangeTabsMenu}
            aria-label="tabs comidas bebidas"
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab icon={<RestaurantIcon />} label="" />
            <Tab icon={<LocalDrinkIcon />} label="" />
            <Tab icon={<AddCircleOutlineIcon />} label="" />
          </Tabs>
        </Box>
      </div>

      <Box
        sx={{
          mt: 18,
          minWidth: 390,
          mb: 10,
        }}
      >
        {value === 0 && (
          <div>
            {productos.map(
              (producto, index) =>
                (producto.categoria === 0 || producto.categoria === 2) && (
                  <Stack
                    spacing={1}
                    key={producto.id + "-" + producto.categoria}
                  >
                    <TituloComida index={index} value={producto.categoria} />
                    <Item>
                      <Chip
                        sx={{
                          mr: 1,
                          boxShadow: 20,
                          width: "290px",

                          textAlign: "left",
                        }}
                        size="large"
                        label={
                          producto.estado === 1 ? (
                            producto.nombre_en_carta
                          ) : (
                            <strike>{producto.nombre_en_carta}</strike>
                          )
                        }
                        variant="outlined"
                        color={producto.estado === 1 ? "success" : "error"}
                      />

                      <IconButton
                        sx={{
                          boxShadow: 10,
                          color: producto.estado === 0 ? "green" : "#fc6f03",
                          mr: 1,
                        }}
                        size="small"
                        aria-label="delete-item"
                        color="inherit"
                        onClick={() => {
                          setEnableDisableProd(index);
                        }}
                      >
                        {producto.estado === 0 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                      <IconButton
                        sx={{ boxShadow: 10, color: "#105aa3" }}
                        size="small"
                        aria-label="edit-item"
                        color="inherit"
                        onClick={() => {
                          editaProducto(index);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Item>
                  </Stack>
                )
            )}
          </div>
        )}
        {value === 1 && (
          <div>
            {productos.map(
              (producto, index) =>
                (producto.categoria === 1 || producto.categoria === 3) && (
                  <Stack
                    spacing={1}
                    key={producto.id + "-" + producto.categoria}
                  >
                    <TituloComida index={index} value={producto.categoria} />
                    <Item>
                      <Chip
                        sx={{
                          mr: 1,
                          boxShadow: 20,
                          width: "290px",

                          textAlign: "left",
                        }}
                        size="large"
                        label={
                          producto.estado === 1 ? (
                            producto.nombre_en_carta
                          ) : (
                            <strike>{producto.nombre_en_carta}</strike>
                          )
                        }
                        variant="outlined"
                        color={producto.estado === 1 ? "success" : "error"}
                      />

                      <IconButton
                        sx={{
                          boxShadow: 10,
                          color: producto.estado === 0 ? "green" : "#fc6f03",
                          mr: 1,
                        }}
                        size="small"
                        aria-label="delete-item"
                        color="inherit"
                        onClick={() => {
                          setEnableDisableProd(index);
                        }}
                      >
                        {producto.estado === 0 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                      <IconButton
                        sx={{ boxShadow: 10, color: "#105aa3" }}
                        size="small"
                        aria-label="edit-item"
                        color="inherit"
                        onClick={() => {
                          editaProducto(index);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Item>
                  </Stack>
                )
            )}
          </div>
        )}
        {value === 2 && (
          <div>
            <Stack spacing={1}>
              <Typography
                sx={{ minWidth: 300, flex: "1 1 100%", mt: 1, ml: 1, mb: 1 }}
                variant="h7"
                id="tableTitle"
                component="div"
              >
                <b>AGREGAR PRODUCTO</b>
              </Typography>

              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE PRODUCTO </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="nombre_producto"
                  value={productoNew.nombre_producto}
                  onChange={setProductoNewValues("nombre_producto")}
                  label="nombre_producto"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE EN CARTA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="nombre_en_carta"
                  value={productoNew.nombre_en_carta}
                  onChange={setProductoNewValues("nombre_en_carta")}
                  label="nombre_en_carta"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>DETALLE EN CARTA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="subtitle_nombre_carta"
                  value={productoNew.subtitle_nombre_carta}
                  onChange={setProductoNewValues("subtitle_nombre_carta")}
                  label="subtitle_nombre_carta"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE EN COMANDA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 28,
                  }}
                  id="nombre_comanda"
                  value={productoNew.nombre_comanda}
                  onChange={setProductoNewValues("nombre_comanda")}
                  label="nombre_comanda"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE EN BOLETA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 28,
                  }}
                  id="nombre_boleta"
                  value={productoNew.nombre_boleta}
                  onChange={setProductoNewValues("nombre_boleta")}
                  label="nombre_boleta"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TIPO PRODUCTO </b>
                </Typography>
                <Select
                  sx={{ width: 370, mt: 1 }}
                  labelId="select-tipo_producto"
                  id="select-tipo_producto-id"
                  value={getTipoProductoByText(productoNew.tipo_producto)}
                  label="tipo_producto"
                  onChange={setProductoNewValues("tipo_producto")}
                >
                  <MenuItem key="0" value="1">
                    ENTRADAS
                  </MenuItem>
                  <MenuItem key="1" value="2">
                    PLATOS
                  </MenuItem>
                  <MenuItem key="2" value="3">
                    VEGETARIANO
                  </MenuItem>
                  <MenuItem key="3" value="4">
                    ENSALADAS
                  </MenuItem>
                  <MenuItem key="4" value="5">
                    AGREGADO EXTRA
                  </MenuItem>
                  <MenuItem key="5" value="6">
                    AGREGADO GRATIS
                  </MenuItem>
                  <MenuItem key="6" value="7">
                    APERITIVOS
                  </MenuItem>
                  <MenuItem key="7" value="8">
                    VINOS
                  </MenuItem>
                  <MenuItem key="8" value="9">
                    CERVEZAS
                  </MenuItem>
                  <MenuItem key="9" value="10">
                    BEBIDAS
                  </MenuItem>
                  <MenuItem key="10" value="11">
                    POSTRES
                  </MenuItem>
                  <MenuItem key="11" value="12">
                    AGREGADO LIQUIDO
                  </MenuItem>
                  <MenuItem key="12" value="13">
                    JUGOS NATURALES
                  </MenuItem>
                  <MenuItem key="13" value="14">
                    AGUAS
                  </MenuItem>
                </Select>
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TIENE AGREGADO GRATIS </b>
                </Typography>
                <Grid>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="radio_button_agregado_gratis"
                      name="radio_button_agregado_gratis"
                      value={productoNew.agregado_gratis}
                      onChange={setProductoNewValues("agregado_gratis")}
                    >
                      <FormControlLabel
                        key="1"
                        value={1}
                        control={<Radio />}
                        label="SI"
                      />
                      <FormControlLabel
                        key="0"
                        value={0}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TIENE AGREGADO LIQUIDO </b>
                </Typography>
                <Grid>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="radio_button_agregado_liquido"
                      name="radio_button_agregado_liquido"
                      value={productoNew.agregado_liquido}
                      onChange={setProductoNewValues("agregado_liquido")}
                    >
                      <FormControlLabel
                        key="1"
                        value={1}
                        control={<Radio />}
                        label="SI"
                      />
                      <FormControlLabel
                        key="0"
                        value={0}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>PRECIO COSTO $</b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="precio_costo"
                  value={productoNew.precio_costo}
                  onChange={setProductoNewValues("precio_costo")}
                  label="precio_costo"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>PRECIO VENTA $</b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="precio_venta"
                  value={productoNew.precio_venta}
                  onChange={setProductoNewValues("precio_venta")}
                  label="precio_venta"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>STOCK</b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="stock"
                  value={productoNew.stock}
                  onChange={setProductoNewValues("stock")}
                  label="stock"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>MARCA PRODUCTO </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="marca_producto"
                  value={productoNew.marca_producto}
                  onChange={setProductoNewValues("marca_producto")}
                  label="marca_producto"
                />
              </Item>
            </Stack>
            <Stack
              sx={{
                width: 300,
                fontSize: 20,
                height: 100,
                ml: 2,
                mt: 2,
                mb: 2,
              }}
              spacing={1}
            >
              <Button
                sx={{ width: 200, fontSize: 20 }}
                size="large"
                onClick={() => {
                  insUpdProducto(0);
                }}
                color="success"
                variant="contained"
                style={{ marginRight: 5 }}
              >
                GUARDAR
              </Button>
            </Stack>
          </div>
        )}
      </Box>

      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoadCocina}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={msgCocina}
            autoHideDuration={null}
          >
            <Alert severity={resultCocina}>{msgResultCocina}</Alert>
          </Snackbar>
        </Stack>
      </div>
      <div>
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
          <AppBar sx={{ position: "fixed", backgroundColor: "#63b899" }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                {idxProdEdit >= 0 ? productoEdit.nombre_producto : "NN"}
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ ml: 1, mt: 10, width: 390 }}>
            <Stack spacing={1}>
              <Typography
                sx={{ minWidth: 300, flex: "1 1 100%", mt: 1, ml: 1, mb: 1 }}
                variant="h7"
                id="tableTitle"
                component="div"
              >
                <b>EDITA EL PRODUCTO</b>
              </Typography>

              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE PRODUCTO </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="nombre_producto_edit"
                  value={productoEdit.nombre_producto}
                  onChange={setProductEditValue("nombre_producto")}
                  label="nombre_producto_edit"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE EN CARTA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="nombre_en_carta_edit"
                  value={productoEdit.nombre_en_carta}
                  onChange={setProductEditValue("nombre_en_carta")}
                  label="nombre_en_carta_edit"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>DETALLE EN CARTA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="subtitle_nombre_carta_edit"
                  value={
                    productoEdit.subtitle_nombre_carta === null
                      ? ""
                      : productoEdit.subtitle_nombre_carta
                  }
                  onChange={setProductEditValue("subtitle_nombre_carta")}
                  label="subtitle_nombre_carta_edit"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE EN COMANDA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 28,
                  }}
                  id="nombre_comanda_edit"
                  value={productoEdit.nombre_comanda}
                  onChange={setProductEditValue("nombre_comanda")}
                  label="nombre_comanda_edit"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE EN BOLETA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 28,
                  }}
                  id="nombre_boleta_edit"
                  value={productoEdit.nombre_boleta}
                  onChange={setProductEditValue("nombre_boleta")}
                  label="nombre_boleta_edit"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TIPO PRODUCTO </b>
                </Typography>
                <Select
                  sx={{ width: 370, mt: 1 }}
                  labelId="select-tipo_producto_edit"
                  id="select-tipo_producto-id_edit"
                  value={getTipoProductoByText(productoEdit.tipo_producto)}
                  label="tipo_producto_edit"
                  onChange={setProductEditValue("tipo_producto")}
                >
                  <MenuItem key="0" value="1">
                    ENTRADAS
                  </MenuItem>
                  <MenuItem key="1" value="2">
                    PLATOS
                  </MenuItem>
                  <MenuItem key="2" value="3">
                    VEGETARIANO
                  </MenuItem>
                  <MenuItem key="3" value="4">
                    ENSALADAS
                  </MenuItem>
                  <MenuItem key="4" value="5">
                    AGREGADO EXTRA
                  </MenuItem>
                  <MenuItem key="5" value="6">
                    AGREGADO GRATIS
                  </MenuItem>
                  <MenuItem key="6" value="7">
                    APERITIVOS
                  </MenuItem>
                  <MenuItem key="7" value="8">
                    VINOS
                  </MenuItem>
                  <MenuItem key="8" value="9">
                    CERVEZAS
                  </MenuItem>
                  <MenuItem key="9" value="10">
                    BEBIDAS
                  </MenuItem>
                  <MenuItem key="10" value="11">
                    POSTRES
                  </MenuItem>
                  <MenuItem key="11" value="12">
                    AGREGADO LIQUIDO
                  </MenuItem>
                  <MenuItem key="12" value="13">
                    JUGOS NATURALES
                  </MenuItem>
                  <MenuItem key="13" value="14">
                    AGUAS
                  </MenuItem>
                </Select>
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TIENE AGREGADO GRATIS </b>
                </Typography>
                <Grid>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="radio_button_agregado_gratis_edit"
                      name="radio_button_agregado_gratis_edit"
                      value={productoEdit.agregado_gratis}
                      onChange={setProductEditValue("agregado_gratis")}
                    >
                      <FormControlLabel
                        key="1"
                        value={1}
                        control={<Radio />}
                        label="SI"
                      />
                      <FormControlLabel
                        key="0"
                        value={0}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>TIENE AGREGADO LIQUIDO </b>
                </Typography>
                <Grid>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="radio_button_agregado_liquido_edit"
                      name="radio_button_agregado_liquido_edit"
                      value={productoEdit.agregado_liquido}
                      onChange={setProductEditValue("agregado_liquido")}
                    >
                      <FormControlLabel
                        key="1"
                        value={1}
                        control={<Radio />}
                        label="SI"
                      />
                      <FormControlLabel
                        key="0"
                        value={0}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>PRECIO COSTO $</b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="precio_costo_edit"
                  value={productoEdit.precio_costo}
                  onChange={setProductEditValue("precio_costo")}
                  label="precio_costo_edit"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>PRECIO VENTA $</b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="precio_venta_edit"
                  value={productoEdit.precio_venta}
                  onChange={setProductEditValue("precio_venta")}
                  label="precio_venta_edit"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>STOCK</b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="stock_edit"
                  value={productoEdit.stock}
                  onChange={setProductEditValue("stock")}
                  label="stock_edit"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>MARCA PRODUCTO </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="marca_producto_edit"
                  value={productoEdit.marca_producto}
                  onChange={setProductEditValue("marca_producto")}
                  label="marca_producto_edit"
                />
              </Item>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                width: 300,
                fontSize: 20,
                height: 100,
                ml: 1,
                mt: 1,
                mb: 1,
              }}
            >
              <Button
                sx={{ width: 150, fontSize: 15, height: 50, mr: 2 }}
                size="large"
                onClick={() => {
                  insUpdProducto(1);
                }}
                color="success"
                variant="contained"
              >
                GUARDAR
              </Button>
              <Button
                sx={{ width: 150, fontSize: 15, height: 50 }}
                size="large"
                onClick={() => {
                  setOpenDialog(false);
                }}
                color="error"
                variant="contained"
              >
                CANCELAR
              </Button>
            </Stack>
          </Box>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
