import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import configData from "./config.json";
import AppbarMenu from "./AppbarMenu";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableBarIcon from "@mui/icons-material/TableBar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Stack from "@mui/material/Stack";
import OutlinedInput from "@mui/material/OutlinedInput";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

async function getMesasStatus(idUsuario, token) {
  const data = await fetch(configData.getMesasStatus, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
  });
  return await data.json();
}

async function setMesaAPI(idUsuario, token, mesaInsUpd) {
  const data = await fetch(configData.setMesa, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idUsuario: idUsuario,
      mesa: mesaInsUpd,
    }),
  });
  return await data.json();
}

async function setMesaStatus(
  idUsuario,
  token,
  idMesa,
  inStatus,
  inMesasId,
  inMesasNum
) {
  const data = await fetch(configData.setMesaStatus, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({
      idMesa: idMesa,
      inStatus: inStatus,
      mesasId: inMesasId,
      mesasNum: inMesasNum,
    }),
  });
  return await data.json();
}

export default function MesasAdmin(props) {
  const mesaNewSch = {
    id: -1,
    nombre_mesa: "",
    numero_mesa: "",
    capacidad: "",
    estado: -1,
  };

  const [isLoading, setIsLoading] = useState(true);
  const [mesas, setMesas] = useState([]);
  const usuario = sessionStorage.getItem("nombre");
  const [value, setValue] = React.useState(0);
  const [mesaNew, setMesaNew] = React.useState(mesaNewSch);

  const [showLoadCocina, setShowLoadCocina] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [msgCocina, setMsgCocina] = React.useState(false);
  const [resultCocina, setResultCocina] = React.useState();
  const [msgResultCocina, setMsgResultCocina] = React.useState();

  const [openDialog, setOpenDialog] = React.useState(false);

  const [titleDialog, setTitleDialog] = React.useState("");

  const [idxMesaEdit, setIdxMesaEdit] = React.useState(-1);

  const [mesaEdit, setMesaEdit] = React.useState({});

  const timer = React.useRef();

  function getTitleAccionMesas() {
    var title = "No hay mesas que gestionar...";

    mesas.map((mesa) => {
      if (mesa.estado !== 0) {
        title = "Elije una mesa";
        return title;
      }
    });

    return title;
  }

  function getMesaEstado(estado) {
    if (estado === 0) return "#168709";
    else if (estado === 1) return "#b9c722";
    else if (estado === 2) return "#bd7415";
    else if (estado === 3) return "#b31414";
    else if (estado === -1) return "#84868a";
  }

  function openMesa(idx) {
    if (mesas[idx].estado_id === 0 || mesas[idx].estado_id === -1) {
      Swal.fire({
        title: "<strong>" + mesas[idx].nombre_mesa + "</strong>",
        icon: "info",
        html:
          "ESTADO : <b>" +
          mesas[idx].estado_text +
          "</b><br/><br/>" +
          "ULTIMO MESERO: " +
          mesas[idx].usuario_text +
          "<br/>" +
          "ULTIMA VENTA: " +
          mesas[idx].total_ultima_vta +
          "<br/><br/>" +
          "<b> QUIERES EDITARLA?</b>",
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: true,
        showDenyButton: true,
        confirmButtonText: "SI",
        denyButtonText: "NO",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: "rgba(0,0,123,0.4)",
      }).then((result) => {
        if (result.isConfirmed) {
          setMesaEdit(mesas[idx]);

          setIdxMesaEdit(idx);

          setOpenDialog(true);
        }
      });
    } else if (mesas[idx].estado_id === 1) {
      Swal.fire({
        title: "<strong>" + mesas[idx].nombre_mesa + "</strong>",
        icon: "info",
        html:
          "ESTADO : <b>" +
          mesas[idx].estado_text +
          "</b><br/><br/>" +
          "ULTIMO MESERO: " +
          mesas[idx].usuario_text +
          "<br/>" +
          "ULTIMA VENTA: " +
          mesas[idx].total_ultima_vta +
          "<br/><br/>" +
          "<b> LIBERAR MESA?</b>",
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: true,
        showDenyButton: true,
        confirmButtonText: "SI",
        denyButtonText: "NO",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: "rgba(0,0,123,0.4)",
      }).then((result) => {
        if (result.isConfirmed) {
          liberaMesa(mesas[idx].id);
        }
      });
    } else {
      Swal.fire({
        title: "<strong>" + mesas[idx].nombre_mesa + "</strong>",
        icon: "info",
        html:
          "ESTADO : <b>" +
          mesas[idx].estado_text +
          "</b><br/><br/>" +
          "ULTIMO MESERO: " +
          mesas[idx].usuario_text +
          "<br/>" +
          "ULTIMA VENTA: " +
          mesas[idx].total_ultima_vta,
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: "rgba(0,0,123,0.4)",
      });
    }
  }

  const handleChangeTabsMenu = (event, newValue) => {
    setValue(newValue);

    setMesaNew(mesaNewSch);

    console.log(mesaNew);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const insUpdMesa = (inOper) => {
    setOpenDialog(false);

    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    setShowLoadCocina(true);
    setLoading(true);

    setResultCocina("info");
    setMsgResultCocina(
      inOper === 0 ? "Creando Mesa..." : "Actualizando Mesa..."
    );
    setMsgCocina(true);

    if (inOper === 1) {
      mesaNew.id = mesaEdit.mesa_id;
      mesaNew.capacidad = mesaEdit.capacidad;
      mesaNew.estado = mesaEdit.estado_id;
      mesaNew.numero_mesa = mesaEdit.numero_mesa;
      mesaNew.nombre_mesa = mesaEdit.nombre_mesa;
    }

    //Actualiza o crea la mesa
    setMesaAPI(idUsuario, token, mesaNew).then(
      (result) => {
        if ("error" in result) logout();

        timer.current = window.setTimeout(() => {
          setMsgCocina(false);
          setLoading(false);
          setShowLoadCocina(false);

          if (result.codigo === 0 || result.codigo === 1) {
            Swal.fire({
              title: inOper === 0 ? mesaNew.nombre_mesa : mesaEdit.nombre_mesa,
              text: inOper === 0 ? "CREADA" : "ACTUALIZADA",
              icon: "success",
            }).then((result) => {
              window.location.href = "/MesasAdmin";
            });
          } else
            Swal.fire({
              title: "ERROR",
              text: result.message,
              icon: "error",
            });
        }, 2000);
      },

      (error) => {
        setMsgCocina(false);
        setLoading(false);
        setShowLoadCocina(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );
  };

  const setMesaNewValue = (prop) => (event) => {
    if (prop === "numero_mesa" || prop === "capacidad" || prop === "estado") {
      if (isNaN(event.target.value)) {
        return null;
      } else setMesaNew({ ...mesaNew, [prop]: event.target.value });
    } else {
      setMesaNew({ ...mesaNew, [prop]: event.target.value });
    }
  };

  const setMesaEditValue = (prop) => (event) => {
    if (
      prop === "numero_mesa" ||
      prop === "capacidad" ||
      prop === "estado_id"
    ) {
      if (isNaN(event.target.value)) {
        return null;
      } else setMesaEdit({ ...mesaEdit, [prop]: event.target.value });
    } else {
      setMesaEdit({ ...mesaEdit, [prop]: event.target.value });
    }
  };

  function liberaMesa(idMesa) {
    setIsLoading(true);

    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    setMesaStatus(idUsuario, token, idMesa, 999, "", "");

    timer.current = window.setTimeout(() => {
      reloadPage();
      setIsLoading(false);
    }, 2000);
  }

  function reloadPage() {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    getMesasStatus(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setMesas(result);
      },

      (error) => {
        console.log("error al rellamar mesas");
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  useEffect(() => {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    const intervalId = setInterval(() => {
      reloadPage();
    }, 30000);

    getMesasStatus(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setMesas(result);
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      {AppbarMenu()}
      <div>
        <Box
          sx={{
            p: 1,
            bgcolor: "white",
            flexDirection: "row",
            backgroundColor: "white",
            display: "flex",
            borderBottom: 1,
            borderColor: "primary.main",
            maxWidth: 600,
            minWidth: 390,
            zIndex: 1000,
            top: 0,
            mt: 8.8,
          }}
          background-color="white"
          position="fixed"
          key="box-space"
          spacing={2}
          alignItems="left"
        >
          <Chip
            sx={{ mt: 1, mr: 1 }}
            size="large"
            label=" MESAS "
            variant="filled"
            color="info"
            width="100%"
          />
          <Tabs
            value={value}
            onChange={handleChangeTabsMenu}
            aria-label="tabs comidas bebidas"
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab icon={<TableBarIcon />} label="" />
            <Tab icon={<AddCircleOutlineIcon />} label="" />
          </Tabs>
        </Box>
      </div>
      <Container>
        <Box
          sx={{
            p: 1,
            flexGrow: 1,
            display: "flex",
            mt: 15,
            ml: 1,
            "& > *": {
              m: 1,
            },
          }}
          key="box-space"
          component="span"
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          //style={{ minHeight: "30px" }}
        >
          {value === 0 && (
            <div>
              <Grid
                container
                spacing={{ xs: 1, sm: 1 }}
                columns={{ xs: 2, sm: 2 }}
              >
                {mesas.map((mesa, index) => (
                  <Grid item xs={1} sm={1} key={mesa.id}>
                    <Item>
                      <Button
                        sx={{
                          bgcolor: getMesaEstado(mesa.estado_id),

                          ":hover": {
                            backgroundColor: "#999797", // theme.palette.primary.main
                            color: "white",
                          },
                        }}
                        size="large"
                        onClick={() => openMesa(index)}
                        id={mesa.mesa_id}
                        key={mesa.mesa_id}
                        variant="contained"
                      >
                        {mesa.nombre_mesa}
                      </Button>
                    </Item>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          {value === 1 && (
            <div>
              <Stack spacing={1}>
                <Typography
                  sx={{ minWidth: 300, flex: "1 1 100%", mt: 1, ml: 1, mb: 1 }}
                  variant="h7"
                  id="tableTitle"
                  component="div"
                >
                  <b>AGREGAR MESA</b>
                </Typography>

                <Item>
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ flexGrow: 1, width: 300 }}
                  >
                    <b>NUMERO MESA </b>
                  </Typography>
                  <OutlinedInput
                    autoComplete="off"
                    sx={{ width: 370, mt: 1, fontSize: 20 }}
                    inputProps={{
                      maxLength: 50,
                    }}
                    id="numero_mesa"
                    value={mesaNew.numero_mesa}
                    onChange={setMesaNewValue("numero_mesa")}
                    label="numero_mesa"
                  />
                </Item>
                <Item>
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ flexGrow: 1, width: 300 }}
                  >
                    <b>NOMBRE MESA </b>
                  </Typography>
                  <OutlinedInput
                    autoComplete="off"
                    sx={{ width: 370, mt: 1, fontSize: 20 }}
                    inputProps={{
                      maxLength: 50,
                    }}
                    id="nombre_mesa"
                    value={mesaNew.nombre_mesa}
                    onChange={setMesaNewValue("nombre_mesa")}
                    label="nombre_mesa"
                  />
                </Item>
                <Item>
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ flexGrow: 1, width: 300 }}
                  >
                    <b>CAPACIDAD </b>
                  </Typography>
                  <OutlinedInput
                    autoComplete="off"
                    sx={{ width: 370, mt: 1, fontSize: 20 }}
                    inputProps={{
                      maxLength: 50,
                    }}
                    id="capacidad"
                    value={mesaNew.capacidad}
                    onChange={setMesaNewValue("capacidad")}
                    label="capacidad"
                  />
                </Item>
                <Item>
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ flexGrow: 1, width: 300 }}
                  >
                    <b> DISPONIBILIDAD </b>
                  </Typography>
                  <Grid>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="radio_button_mesa_edit"
                        name="radio_button_mesa_edit"
                        value={mesaNew.estado !== -1 ? 0 : -1}
                        onChange={setMesaNewValue("estado")}
                      >
                        <FormControlLabel
                          key="0"
                          value={0}
                          control={<Radio />}
                          label="Operativa"
                        />
                        <FormControlLabel
                          key="-1"
                          value={-1}
                          control={<Radio />}
                          label="NO Operativa"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Item>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  width: 300,
                  fontSize: 20,
                  height: 100,
                  ml: 1,
                  mt: 1,
                  mb: 1,
                }}
              >
                <Button
                  sx={{ width: 150, fontSize: 15, height: 50, mr: 2 }}
                  size="large"
                  onClick={() => {
                    insUpdMesa(0);
                  }}
                  color="success"
                  variant="contained"
                >
                  CREAR
                </Button>
              </Stack>
            </div>
          )}
        </Box>
      </Container>
      <div>
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
          <AppBar sx={{ position: "fixed", backgroundColor: "#63b899" }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                {idxMesaEdit >= 0 ? mesaEdit.nombre_mesa : "NN"}
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ ml: 1, mt: 10, width: 390 }}>
            <Stack spacing={1}>
              <Typography
                sx={{ minWidth: 300, flex: "1 1 100%", mt: 1, ml: 1, mb: 1 }}
                variant="h7"
                id="tableTitle"
                component="div"
              >
                <b>EDITA LA MESA</b>
              </Typography>

              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NUMERO MESA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="numero_mesa"
                  value={mesaEdit.numero_mesa}
                  onChange={setMesaEditValue("numero_mesa")}
                  label="numero_mesa"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>NOMBRE MESA </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="nombre_mesa"
                  value={mesaEdit.nombre_mesa}
                  onChange={setMesaEditValue("nombre_mesa")}
                  label="nombre_mesa"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b>CAPACIDAD </b>
                </Typography>
                <OutlinedInput
                  autoComplete="off"
                  sx={{ width: 370, mt: 1, fontSize: 20 }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  id="capacidad"
                  value={mesaEdit.capacidad}
                  onChange={setMesaEditValue("capacidad")}
                  label="capacidad"
                />
              </Item>
              <Item>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{ flexGrow: 1, width: 300 }}
                >
                  <b> DISPONIBILIDAD </b>
                </Typography>
                <Grid>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="radio_button_mesa_edit"
                      name="radio_button_mesa_edit"
                      value={mesaEdit.estado_id !== -1 ? 0 : -1}
                      onChange={setMesaEditValue("estado_id")}
                    >
                      <FormControlLabel
                        key="0"
                        value={0}
                        control={<Radio />}
                        label="Operativa"
                      />
                      <FormControlLabel
                        key="-1"
                        value={-1}
                        control={<Radio />}
                        label="NO Operativa"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Item>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                width: 300,
                fontSize: 20,
                height: 100,
                ml: 1,
                mt: 1,
                mb: 1,
              }}
            >
              <Button
                sx={{ width: 150, fontSize: 15, height: 50, mr: 2 }}
                size="large"
                onClick={() => {
                  insUpdMesa(1);
                }}
                color="success"
                variant="contained"
              >
                GUARDAR
              </Button>
              <Button
                sx={{ width: 150, fontSize: 15, height: 50 }}
                size="large"
                onClick={() => {
                  setOpenDialog(false);
                }}
                color="error"
                variant="contained"
              >
                CANCELAR
              </Button>
            </Stack>
          </Box>
        </Dialog>
      </div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoadCocina}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={msgCocina}
            autoHideDuration={null}
          >
            <Alert severity={resultCocina}>{msgResultCocina}</Alert>
          </Snackbar>
        </Stack>
      </div>
    </React.Fragment>
  );
}
