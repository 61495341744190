import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import configData from "./config.json";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

async function getMesas(idUsuario, token) {
  const data = await fetch(configData.getMesas, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
  });
  return await data.json();
}

export default function Mesas(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [mesas, setMesas] = useState([]);
  const usuario = sessionStorage.getItem("nombre");

  const timer = React.useRef();

  function getTitleAccionMesas() {
    var title = "No hay mesas que gestionar...";

    mesas.map((mesa) => {
      if (mesa.estado === 2 || mesa.estado === 3) {
        title = "Elije una mesa";
        return title;
      }
    });

    return title;
  }

  function getMesaEstado(estado) {
    if (estado === 0) return "#168709";
    else if (estado === 1) return "#b9c722";
    else if (estado === 2) return "#bd7415";
    else if (estado === 3) return "#b31414";
    else if (estado === -1) return "#84868a";
  }

  function openMesa(idMesa, nombreMesa, estado) {
    sessionStorage.setItem("currentMesa", idMesa);
    sessionStorage.setItem("nombreMesa", nombreMesa);
    sessionStorage.setItem("estadoMesa", estado);
    window.location.href = "/MenuMesa";
  }

  function reloadPage() {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    getMesas(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setMesas(result);
      },

      (error) => {
        console.log("error al rellamar mesas");
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  useEffect(() => {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    const intervalId = setInterval(() => {
      reloadPage();
    }, 30000);

    getMesas(idUsuario, token).then(
      (result) => {
        if ("error" in result) logout();
        setMesas(result);
      },

      (error) => {
        setIsLoading(false);

        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error",
        });
      }
    );

    timer.current = window.setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          {isLoading && <CircularProgress color="inherit" />}
        </Backdrop>
      </div>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar color="primary" style={{ height: "100px", maxHeight: "100px" }}>
          <Toolbar style={{ height: "100px", maxHeight: "100px" }}>
            <Grid
              sx={{ mt: 1 }}
              container
              justifyContent="flex-start"
              width="400px"
            >
              <Avatar
                sx={{ width: 32, height: 32, mr: 1 }}
                alt={usuario}
                src="/static/images/avatar/1.jpg"
              />
              <Chip
                sx={{ color: "white", fontSize: 20 }}
                label={usuario}
                variant="filled"
                size="medium"
              />
              <Typography
                variant="h6"
                component="div"
                sx={{ mt: 1, flexGrow: 1, width: 600 }}
              >
                Hola, {getTitleAccionMesas()}
              </Typography>
            </Grid>
            <Grid sx={{ mt: 1 }} container justifyContent="flex-end">
              <Button
                size="small"
                onClick={() => logout()}
                color="error"
                variant="contained"
              >
                SALIR
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Container>
        <Box
          sx={{
            p: 1,
            flexGrow: 1,
            mt: 15,
            display: "flex",
            "& > *": {
              m: 1,
            },
          }}
          key="box-space"
          component="span"
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          //style={{ minHeight: "30px" }}
        >
          <Grid container spacing={{ xs: 1, sm: 1 }} columns={{ xs: 2, sm: 2 }}>
            {mesas.map(
              (mesa) =>
                (mesa.estado === 2 || mesa.estado === 3) && (
                  <Grid item xs={1} sm={1} key={mesa.id}>
                    <Item>
                      <Button
                        sx={{
                          bgcolor: getMesaEstado(mesa.estado),

                          ":hover": {
                            backgroundColor: "#999797", // theme.palette.primary.main
                            color: "white",
                          },
                          width: "200px",
                        }}
                        size="large"
                        onClick={() =>
                          openMesa(mesa.id, mesa.nombre_mesa, mesa.estado)
                        }
                        id={mesa.id}
                        key={mesa.id}
                        variant="contained"
                      >
                        {mesa.nombre_mesa}
                      </Button>
                    </Item>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
