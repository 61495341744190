import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Fab from "@mui/material/Fab";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import imgEmitirCuenta from "./imgs/emitirCuenta.png";
import imgCerrarMesa from "./imgs/cerrarMesa.png";
import { maxHeight } from "@mui/system";

import configData from "./config.json";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const images = [
  {
    url: imgCerrarMesa,
    title: "CERRAR MESA",
    width: "100%",
    id: 1,
  },
  {
    url: imgEmitirCuenta,
    title: "EMITIR CUENTA",
    width: "100%",
    id: 0,
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 300,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 150,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

function backToMesas() {
  sessionStorage.removeItem("currentOper");
  sessionStorage.removeItem("currentMesa");
  sessionStorage.removeItem("nombreMesa");
  sessionStorage.removeItem("estadoMesa");

  window.location.href = "/Mesas";
}

function logout() {
  sessionStorage.clear();
  window.location.href = "/";
}

async function cerrarMesa(idUsuario, token, idMesa) {
  const data = await fetch(configData.cerrarMesa, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      idUsuario: idUsuario,
      token: token,
    },
    body: JSON.stringify({ idMesa: idMesa, idUsuario: idUsuario }),
  });
  return await data.json();
}

export default function MenuMesas(props) {
  const usuario = sessionStorage.getItem("nombre");
  const currentMesa = sessionStorage.getItem("currentMesa");
  const nombreMesa = sessionStorage.getItem("nombreMesa");
  const currentOper = sessionStorage.getItem("currentOper");

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [severity, setSeverity] = React.useState();
  const [msg, setMsg] = React.useState();

  const timer = React.useRef();

  const closeMesa = () => {
    let idUsuario = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("accessToken");

    if (!show) {
      setSeverity("info");
      setMsg("Cerrando la " + nombreMesa);
      setShow(true);
      setLoading(true);
      setDialog(true);

      cerrarMesa(idUsuario, token, currentMesa).then(
        (result) => {
          if ("error" in result) logout();

          timer.current = window.setTimeout(() => {
            setLoading(false);
            setShow(false);
            setDialog(false);

            if (result.codigo === 0)
              Swal.fire({
                title: result.message,
                text: "LA MESA HA QUEDADO DISPONIBLE",
                icon: "success",
              }).then((result) => {
                Swal.fire({
                  showCloseButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  backdrop: 'rgba(0,0,123,0.4)',
                  title: "Información del pago?",
                  text: "¿Quieres ingresar los datos del pago?",
                  showDenyButton: true,
                  confirmButtonText: "SI",
                  denyButtonText: "NO",
                  icon: "question"
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = "/DatosPago";
                  } else if (result.isDenied) {
                    backToMesas();
                  }
                });
              });
            else
              Swal.fire({
                title: "ERROR",
                text: result.message,
                icon: "error",
              });

            sessionStorage.removeItem("currentOper");
          }, 2000);
        },

        (error) => {
          setLoading(false);
          setDialog(false);
          setShow(false);

          Swal.fire({
            title: "ERROR",
            text: error,
            icon: "error",
          });

          sessionStorage.removeItem("currentOper");
        }
      );
    }
  };

  function operacionMesa(props) {
    if (props === 0) {
      if (parseInt(sessionStorage.getItem("estadoMesa")) !== 0) {
        sessionStorage.setItem("currentOper", "cuenta");
        window.location.href = "/Cuenta";
      } else {
        Swal.fire({
          title: "MESA SIN CUENTA",
          text: "LA MESA NO TIENE PEDIDOS ABIERTOS",
          icon: "success",
        }).then((result) => {
          return null;
        });
      }
    } else if (props === 1) {
      
      if (parseInt(sessionStorage.getItem("estadoMesa")) === 1) {
        Swal.fire({
          
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: 'rgba(0,0,123,0.4)',
 
          title: "No se ha emitido la cuenta para esta Mesa",
          text: "¿Quieres cerrar la mesa de todas formas?",
          showDenyButton: true,
          confirmButtonText: "SI",
          denyButtonText: "NO",
          icon: "question"
        }).then((result) => {
          if (result.isConfirmed) {
            closeMesa();
          } else if (result.isDenied) {
            return null;
          }
        });
      } else {
        closeMesa();
      }
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar color="primary" style={{ height: "100px", maxHeight: "100px" }}>
          <Toolbar style={{ height: "100px", maxHeight: "100px" }}>
            <Grid
              sx={{ mt: 3 }}
              container
              justifyContent="flex-start"
              width="200px"
            >
              <Avatar
                sx={{ width: 32, height: 32, mr: 1 }}
                alt={usuario}
                src="/static/images/avatar/1.jpg"
              />
              <Chip
                sx={{ color: "white", fontSize: 20 }}
                label={usuario}
                variant="filled"
                size="medium"
              />
              <Typography
                variant="h6"
                component="div"
                sx={{ mt: 1, flexGrow: 1, width: 400 }}
              >
                Qué quieres hacer?
              </Typography>
            </Grid>
            <Grid sx={{ mt: 2 }} container justifyContent="flex-end">
              <Button
                size="small"
                onClick={() => backToMesas()}
                color="error"
                variant="contained"
              >
                Cancelar
              </Button>
            </Grid>
          </Toolbar>
          <Box
            sx={{
              p: 1,
              flexGrow: 1,
              backgroundColor: "white",
              mt: 2,
              display: "flex",
              "& > *": {
                m: 1,
              },
            }}
            component="span"
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            
          >
            <Chip
                    sx={{
                      mt: 1,
                      width: 200,
                      height: 50,
                      fontSize: 20,
                    }}
              size="medium"
              label={nombreMesa}
              variant="filled"
              color="warning"
              width="100%"
            />
          </Box>
        </AppBar>
      </ElevationScroll>

      <Box
        sx={{

          mt: 20
        }}
      >
        {images.map((image) => (
          <ImageButton
            focusRipple
            key={image.title}
            onClick={() => operacionMesa(image.id)}
            style={{
              width: image.width,
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="h3"
                color="inherit"
                sx={{
                  position: "relative",
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                {image.title}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          </ImageButton>
        ))}
      </Box>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={show}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={dialog}
            autoHideDuration={6000}
          >
            <Alert severity={severity}>{msg}</Alert>
          </Snackbar>
        </Stack>
      </div>
    </React.Fragment>
  );
}
