import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppbarMenu from "./AppbarMenu";

export default function HomeAdmin(props) {


  return (
    <React.Fragment>

      {AppbarMenu()}

      <Box
        sx={{
          mt: 18,
          minWidth: 390,
          mb: 10,
        }}
      >
        </Box>
    </React.Fragment>
    
  );


}
